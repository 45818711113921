<template>
  <div class="company-about" v-bind="initData">
    <div class="modal" v-if="info">
      <div class="container centered">
        <div class="header-about">
          <div>
            <span class="title primary-color" bold>{{ info.title }}</span>
          </div>
          <div>
            <router-link :to="prevRoute.path ? prevRoute.path : '/'" class="button">
              <i class="fa fa-times"></i>
            </router-link>
          </div>
        </div>
        <div class="content">
          <div class="picture">
            <img src="https://cdn.models1a.com/website/about/roaa2.jpg" alt="Fondo modelo en jacuzzi"/>
          </div>
          <div class="text" v-html="removeHTMLEntities(info.text)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "../../store/index.js";
  export default {
    data: () => {
      return {
        info: null,
        locale: null,
        prevRoute: [],
      };
    },
    metaInfo: function () {
      const title = this.locale ? "- " + this.locale.about.description : "";
      return {
        title: `Models1A ${title}`,
      };
    },
    props: {
      title: String,
      content: String,
    },
    methods: {
      /**
       * Funcion encargada de reemplazar las entidades html por su representacion
       * @param {*} textoHTML
       */
      removeHTMLEntities: function (textoHTML) {
        const entidadesHTML = {
          "&lt;": "<",
          "&gt;": ">",
          "&amp;": "&",
          "&quot;": '"',
          "&apos;": "'",
          "&nbsp;": " ",
        };

        let textoLimpio = textoHTML;
        for (const entidad in entidadesHTML) {
          if (entidadesHTML.hasOwnProperty(entidad)) {
            const expresionRegular = new RegExp(entidad, "g");
            textoLimpio = textoLimpio.replace(expresionRegular, entidadesHTML[entidad]);
          }
        }
        return textoLimpio;
      },
      getLang: async function () {
        if (store.getters["iflanguage"]) {
          let dataLang = await store.getters["dataLang"];
          this.locale = await store.getters["localeLang"];
          this.info = dataLang.ourCompany;
        }
      },
    },
    computed: {
      initData: async function () {
        this.getLang();
      },
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.prevRoute = from;
        const mbody = document.querySelector("body");
        mbody.classList.add("onmodal");
      });
    },
    beforeRouteLeave(to, from, next) {
      const mbody = document.querySelector("body");
      mbody.classList.remove("onmodal");
      next();
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  .company-about {
    .modal {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.88);
      z-index: 999;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      .container {
        position: absolute;
        background: $white;
        max-width: 1024px;
        max-height: calc(100% - 120px);
        width: 100%;
        height: 100%;
        border-radius: $mradius;
        overflow: hidden;
        .header-about {
          height: 44px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            margin-left: $mpadding;
          }
          .button {
            padding: $mpadding;
          }
        }
        .content {
          max-height: calc(100% - 44px);
          overflow-y: auto;
          padding: $mpadding;
          &::-webkit-scrollbar {
            width: 6px;
          }
          .text {
            margin-top: $mpadding/2;
            > div p {
              margin: 0 0 $mpadding 0;
            }
          }
          .picture {
            img {
              max-width: 100%;
              border-radius: $radius-8;
            }
          }
        }
      }
    }
  }
</style>
